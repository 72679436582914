<div class="controllers-container">
  <div
    #container
    sbSlideController
    [$container]="container"
    [$next]="next"
    [$previous]="previous"
    class="plans-container"
  >
    @for (plan of getFilteredPlans(subscriptionInterval); track plan; let first = $first; let last = $last) {
      <div
        class="plan"
        [class.selected]="plan.id === selectedPlanId || plan.id === currentPlanId"
        [style.scroll-snap-align]="first || last ? 'center' : 'end'"
      >
        <h3 class="plan-title">{{ plan.attributes.title }}</h3>
        <p class="plan-price">
          {{ plan.attributes.price_exc_tax | number: '1.0-0' }}
          {{ plan.attributes.currency_code }}
          <span class="plan-frequency">/{{ subscriptionInterval === 'year' ? 'år' : 'måned' }}</span>
        </p>
        <p class="plan-vouchers">{{ plan.attributes.invoice_limit | number: '1.0-0' }} ordre</p>
        @if (subscriptionInterval === 'year') {
          <p class="plan-early">
            ({{ plan.attributes.price_exc_tax / 12 | number: '1.0-0' }} {{ plan.attributes.currency_code }} /måned)
          </p>
        }
        @if (plan.id !== selectedPlanId && plan.id !== currentPlanId) {
          <button
            mat-flat-button
            color="primary"
            [disabled]="loading"
            (click)="onSelectPlan(+plan.id)"
            class="w-100 mt-5"
          >
            Vælg plan
          </button>
        }
        @if (plan.id === selectedPlanId && plan.id !== currentPlanId) {
          <fa-icon [icon]="['fal', 'check-circle']" [size]="'2x'" class="mt-auto text-success mb-1"></fa-icon>
        }
        @if (plan.id === currentPlanId) {
          <p class="mt-auto fw-bold mb-2" data-testid="current-plan-label">Nuværende abonnement</p>
        }
      </div>
    }
  </div>

  <button #previous type="button" class="slide-controller previous">
    <fa-icon [icon]="['fal', 'chevron-left']"></fa-icon>
  </button>
  <button #next type="button" class="slide-controller next">
    <fa-icon [icon]="['fal', 'chevron-right']"></fa-icon>
  </button>
</div>
